import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import roomImg1 from "../images/Good House/4.jpeg";
import roomImg2 from "../images/Good House/6.jpeg";
import EventTwo from "../images/events/3.jpg";

const InfoDiv = styled.section`
  width: 100%;
  height: 90%;
  padding: 0rem 1rem;
  margin: 1rem auto;

  @media screen and (max-width: 480px) {
    padding: 0rem;
    width: 95%;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  flex: 1;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  line-height: 2rem;

  h1 {
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 1rem;
  }
  h2 {
    color: #87888a;
    text-align: center;
  }

  h3 {
    font-weight: bold;
    text-align: center;
  }

  p {
    margin-bottom: 1rem;
    text-align: center;
  }

  @media screen and (max-width: 760px) {
    width: 100%;
    padding: 0;
    font-size: 0.9rem;

    p {
      margin-bottom: 0.5rem;
      text-align: left;
    }
  }
`;

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;

  div.textDiv {
    padding: 0 2rem;

    h2 {
      color: var(--second-color);
    }

    p {
      margin: 1rem;
      line-height: 2rem;
    }
  }

  img {
    max-width: 1000px;
    width: 100%;
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    padding: 2rem 0 0;
    margin: 0 auto;
    // grid-template-rows: 500px;

    div.textDiv {
      padding: 0;
    }
  }
`;

const EventContent = () => {
  return (
    <InfoDiv>
      <Container>
        <h1>Celebrate Your Special Occasions With Us</h1>
        <p>
          Our flagship property, The GoodHouse PHC is ideal for hosting various
          types of events including wedding ceremonies and receptions, music
          shows, baby dedications, birthday parties, book launches, corporate
          retreats and other types of events.
        </p>
        <Button to={`/contact`} primary="true">
          Request Information
        </Button>
      </Container>
      <PageContainer>
        <div>
          <img src={EventTwo} alt="" />
        </div>
        <div className="textDiv">
          <h2>Weddings</h2>
          <p>
            Whether you’re thinking of a venue for your traditional wedding
            ceremony, the joining ceremony or the wedding reception, we’ve got
            you covered.
          </p>
          <p>
            The GoodHouse PHC can contain up to 400 people on its grounds and
            has beautiful natural greenery, covered with different kinds of
            bright flowers.
          </p>
          <p>
            We also offer constant electricity, hence you don’t need to think of
            sourcing your own power.
          </p>
        </div>
      </PageContainer>
      <PageContainer>
        <div className="textDiv">
          <h2>Birthdays</h2>
          <p>
            Birthdays are special to lots of us, so why not go all out. We
            provide a magnificent and secure environment for you to celebrate
            your big day.
          </p>
          <p>
            You can plan for an outdoor pool/garden party or you can take it
            inside, we have adequate space for your preference.
          </p>
        </div>
        <div>
          <img src={roomImg1} alt="" />
        </div>
      </PageContainer>
      <PageContainer>
        <div>
          <img src={roomImg2} alt="" />
        </div>
        <div className="textDiv">
          <h2>Celebrations</h2>
          <p>
            The GoodHouse PHC let’s you celebrate other types of events too.
            Product launches, baby dedications, pre and post wedding parties
            etc. Our space is ideal.
          </p>
        </div>
      </PageContainer>
    </InfoDiv>
  );
};

export default EventContent;
