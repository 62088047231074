import React from "react";
import styled from "styled-components";
// import { Button } from "./Button";
import roomImg from "../images/Something New Restaurant/18.jpg";

const InfoDiv = styled.section`
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0.5rem 0;
  margin: 0 auto;
  background: var(--dark-bg);

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

const Container = styled.div`
  padding: 0;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: var(--dark-bg-txt);

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.4;
  padding: 0rem 2rem;
  order: revert;

  h1 {
    color: #fff;
    text-align: center;
    font-size: 2rem;
    margin: 0 auto;
  }

  p {
    margin-bottom: 2rem;
    text-align: center;
  }
  h4 {
    color: #94410a;
    margin-top: 2rem;
  }
`;

const ColumnRight = styled.div`
  padding: 1rem;
  display: flex;
  max-height: 500px;

  @media screen and (max-width: 768px) {
    // order: $/{(props) => (props.reverse ? "2" : "1")};
    width: 90%;
    padding: 1rem;
    margin: 0 auto;
  }

  p {
    text-align: left;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const IntroHall = () => {
  return (
    <InfoDiv>
      <Container>
        <ColumnRight>
          <img src={roomImg} alt="" />
        </ColumnRight>
        <ColumnLeft>
          <h1>Something New Restaurant</h1>
          <p>
            Something New Restaurant and Bar Welcome to our beautiful outdoor
            restaurant and bar. We offer delicious local and continental meals,
            as well as various drinks to suit your mood. The space is serene,
            modern but with a natural cozy feel.
          </p>
          <p>
            We can also host your parties and events, whether it’s birthdays,
            engagement parties or social gigs, we can host and even cater for
            your event.
          </p>
          <p>
            {" "}
            Come in for the great meals, stay for the warm and friendly service,
            good music and you can even sing karaoke with friends.
          </p>
        </ColumnLeft>
      </Container>
    </InfoDiv>
  );
};

export default IntroHall;
