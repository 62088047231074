import React from "react";
import Rooms from "../components/Rooms";
// import PageHero from "../components/PageHero";
import Listing from "../components/Listing";
import List from "../components/List";

const Room = () => {
  return (
    <>
      {/* <PageHero name="Listings" /> */}
      <List />
      <Listing />
      <Rooms />
    </>
  );
};

export default Room;
