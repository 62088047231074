import React from "react";
import Hero from "../components/Hero";
import { SliderDataTwo } from "../data/SliderData";
import EventContent from "../components/EventContent";

const EventsPage = () => {
  return (
    <>
      <Hero slides={SliderDataTwo} />
      <EventContent />
      {/* <Hall /> */}
    </>
  );
};

export default EventsPage;
