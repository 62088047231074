import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import goodHouse from "../images/Good House/28.jpg";
import roomBlue from "../images/R & B/19.jpg";

const Container = styled.div`
  padding: 0 5rem;
  width: 100%;
  line-height: 2rem;
  //   background: rgba(83, 83, 83, 0.1);

  h1 {
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 1rem;
  }
  h2 {
    color: #f1ca65;
    text-align: center;
  }

  h3 {
    color: var(--third-color);
    font-weight: bold;
    text-align: center;
  }

  p {
    margin-bottom: 1rem;
    text-align: center;
  }

  @media screen and (max-width: 760px) {
    padding: 2rem;
    font-size: 0.9rem;
  }
`;

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;

  div.textDiv {
    padding: 0 2rem;

    h2 {
      color: #f1ca65;
    }

    p {
      margin: 1rem;
      line-height: 2rem;
    }
  }

  img {
    max-width: 1000px;
    width: 100%;
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    padding: 2rem 0 0;
    margin: 0 auto;
    order: $/{({reverse}) => (reverse ? '2' : '1')};
    // grid-template-rows: 500px;

    div.textDiv {
      padding: 0;
    }
  }
`;

const Listing = () => {
  return (
    <div>
      <Container>
        <h1>Our Accommodations</h1>
        <p>
          Check out our various offerings below. We have carefully curated our
          offerings to meet the various needs of the discerning luxury guest.
        </p>
        <PageContainer>
          <div className="textDiv">
            <h2>The Good House PHC</h2>
            <h3>Spacious Luxury Villa with a swimming pool</h3>
            <p>
              The GoodHouse PHC is a beautiful six-bedroom villa located in
              within the city of Port Harcourt. This property is next to the
              18-hole Python golf course, a stone throw from the Obi Wali
              International Conference Centre, Port Harcourt Pleasure Park and
              the Airforce City Airport. It’s perfect for family/friends
              getaways, celebrations and corporate retreats or stays.
            </p>
            <p>
              It’s surrounded with lush greenery, a beautiful swimming pool and
              spacious parking areas. Within, we have a reception, two living
              rooms, dining area, a mini-gym and six beautifully furnished
              bedrooms, all en-suite.
            </p>
            <p>
              What’s more, we offer free wifi, cable tv, 24/7 electricity, free
              parking, expansive outdoor space available to guests at no extra
              cost and adequate security.
            </p>
            <Link
              to={`/booknow/the_good_house`}
              className="btn btn-outline-primary btn-block btn-lg float-right "
            >
              Book Now
            </Link>
          </div>
          <div>
            <img src={goodHouse} alt="" />
          </div>
        </PageContainer>
        <PageContainer>
          <div>
            <img src={roomBlue} alt="" />
          </div>
          <div className="textDiv">
            <h2>Rhythm and Blues</h2>
            <h3>Luxury Studio apartments with privacy and outdoor amenities</h3>
            <p>
              These chic studio apartments are located in the serene and
              high-brow area of Old G.R.A. Port Harcourt. They are next to the
              Port Harcourt Club golf course. These two apartments are secluded
              and offer tranquility.
            </p>
            <p>
              The apartments are comfortably furnished with facilities that
              guests can relax both indoors and outdoors. Each apartment comes
              with a king-size bed, sitting and dining area within the same
              space. Each unit also has its own well-appointed bathroom with a
              bath tub and fully equipped kitchen with a washing machine.
            </p>
            <p>
              Outside the rooms, guests can relax in our lovely garden or go for
              a dip in the swimming pool provided.
            </p>
            <Link
              to={`/booknow/Rhythm`}
              className="btn btn-outline-primary btn-block btn-lg float-right "
            >
              Book Now
            </Link>
          </div>
        </PageContainer>
      </Container>
    </div>
  );
};

export default Listing;
