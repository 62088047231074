import React, { useState } from "react";
import axios from "axios";

const TestPage = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [message, setMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://admin.thegoodhouseng.com/contacts", {
        name: name,
        email: email,
        phone: phone,
        message: msg,
      })
      .then((res) => {
        if (res.status === 201) {
          setMessage(res.statusText);
          setName("");
          setEmail("");
          setPhone("");
          setMsg("");
        } else {
          setMessage(res.data.error);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <>
      <div className="b-example-divider"></div>
      <div className="container marketing my-5 py-3">
        <h1 className="text-center text-primary pb-2">Contact Us</h1>
        {/* <!-- Three columns of text below the carousel --> */}
        <div className="row">
          <div className="col-lg-4 border-start border-success border-3">
            <hr />
            <h2 className="text-info">Address:</h2>
            <ul>
              <li>
                Plot 18, G.R.A. Phase 8, Off G.U. Ake Road, Port Harcourt.
              </li>
            </ul>
            <hr />
          </div>
          <div className="col-lg-4 border-start border-success border-3">
            <hr />
            <h2 className="text-info">Phone:</h2>
            <ul>
              <li>(234) 704 279 5983</li>
            </ul>
            <hr />
          </div>
          <div className="col-lg-4 border-start border-success border-3 bg-light">
            <hr />
            <h2 className="text-info">Email:</h2>
            <ul>
              <li>info@thegoodhouseng.com</li>
            </ul>
            <hr />
          </div>
          <p className="text-center">
            <b>Check-in:</b> after 2:00 pm – <b>Check-out:</b> before 12:00 pm
          </p>
          <p className="text-center">
            Free private parking available for guests.
          </p>
        </div>
      </div>

      <div className="b-example-divider"></div>
      {/* <!-- open Form--> */}
      <div className="container my-5 py-5">
        <div className="bd-example">
          <h1 className="text-center text-primary pb-2">Send Us a Message</h1>
          <p>{message}</p>
          <form className="row g-3" onSubmit={onSubmit}>
            <div className="col-md-4">
              <label htmlFor="validationServer01" className="form-label">
                Fullname
              </label>
              <input
                type="text"
                className="form-control"
                id="validationServer01"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div className="col-md-4">
              <label htmlFor="validationServer02" className="form-label">
                Email
              </label>
              <input
                type="text"
                value={email}
                className="form-control"
                id="validationServer02"
                placeholder="email address"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div className="col-md-4">
              <label htmlFor="validationServerUsername" className="form-label">
                Phone
              </label>
              <div className="input-group has-validation">
                <input
                  type="text"
                  className="form-control"
                  value={phone}
                  id="validationServerUsername"
                  placeholder="Phone Number"
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
                <div className="invalid-feedback">
                  Please choose a username.
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <label htmlFor="validationServerUsername" className="form-label">
                Message
              </label>
              <div className="input-group has-validation">
                <textarea
                  rows="8"
                  className="form-control"
                  id="validationServerMessage"
                  placeholder="Message"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  required
                ></textarea>
                <div className="invalid-feedback">
                  Please choose a username.
                </div>
              </div>
            </div>
            <div className="col-12">
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TestPage;
