import React from "react";
import FeaturedRooms from "../components/FeaturedRooms";
import Hero from "../components/Hero";
import { SliderData } from "../data/SliderData";
import InfoSection from "../components/InfoSection";
import IntroHall from "../components/Restaurant";
// import Hall from "../components/Hall";
import Accomodation from "../components/Accomodation";
import Events from "../components/Events";

const Home = () => {
  return (
    <>
      <Hero slides={SliderData} />
      <InfoSection />
      <Accomodation />
      <Events />
      <IntroHall />
      <FeaturedRooms />
      {/* <Hall /> */}
    </>
  );
};

export default Home;
