import room1 from "../images/Good House/7.JPG";
import room2 from "../images/2.jpg";
import room3 from "../images/3.jpg";
import room4 from "../images/4.jpg";
import img8 from "../images/3.jpg";
import img16 from "../images/R & B/16.jpg";
// import img12 from "../images/2.jpg";

export const items = [
  {
    sys: {
      id: "1",
    },
    fields: {
      name: "Blues",
      slug: "Blues",
      type: "double",
      price: 35000,
      size: 500,
      capacity: 2,
      pets: false,
      breakfast: false,
      featured: true,
      description:
        "A studio apartment furnished with a fully equipped kitchen with a washing machine, splendid bathroom and a swimming pool.",
      extras: [
        "Plush pillows and breathable bed linens",
        "Soft, oversized bath towels",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security",
        "Internet",
        "Comfortable beds",
      ],
      images: [
        {
          fields: {
            file: {
              url: img8,
            },
          },
        },
        {
          fields: {
            file: {
              url: room2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room4,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "2",
    },
    fields: {
      name: "Rhythm",
      slug: "Rhythm",
      type: "family",
      price: 35000,
      size: 700,
      capacity: 6,
      pets: false,
      breakfast: false,
      featured: true,
      description:
        "Single room furnished with a fully equipped kitchen with a washing machine, splendid bathroom and a swimming pool.",
      extras: [
        "Plush pillows and breathable bed linens",
        "Soft, oversized bath towels",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security",
        "Internet",
        "Comfortable beds",
      ],
      images: [
        {
          fields: {
            file: {
              url: img16,
            },
          },
        },
        {
          fields: {
            file: {
              url: room2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room4,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "3",
    },
    fields: {
      name: "The Good House PHC",
      slug: "the_good_house",
      type: "presidential",
      price: 250000,
      weekend_price: 270000,
      caution: 50000,
      cleaning: 20000,
      size: 1000,
      capacity: 10,
      pets: false,
      breakfast: false,
      featured: true,
      description:
        "6 bedroom duplex with all rooms ensuite, swimming pool, barbeque area, free wi-fi, laundry and security, located in a serene environment.",
      extras: [
        "Plush pillows and breathable bed linens",
        "Soft, oversized bath towels",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security",
        "Internet",
        "Comfortable beds",
      ],
      images: [
        {
          fields: {
            file: {
              url: room1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room4,
            },
          },
        },
      ],
    },
  },
];
