import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { menuData } from "../data/MenuData";
import { Button } from "./Button";
import { FaBars } from "react-icons/fa";
import logo from "../images/Logo-cropped.png";

const Nav = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  width: 100%;
  z-index: 100;
  position: fixed;
  background-color: #000;
`;

const NavLink = css`
  color: #fff;
  display: flex;
  align-item: right;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;

  @media screen and (max-width: 960px) {
    padding: 0;
  }
`;

const Logo = styled(Link)`
  ${NavLink}
  font-size: 1.5rem;
  font-weight: 500;

  @media screen and (max-width: 960px) {
    overflow: visible;
    font-size: 1.2rem;
  }
  @media screen and (max-width: 280px) and (max-height: 653px) {
    /* CSS to hide <p> elements */
    .hide-text {
      display: none;
    }
  }
`;

const MenuBars = styled(FaBars)`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    margin-right: 10px;
    height: 35px;
    width: 40px;
    cursor: pointer;
    color: white;
    top: 0;
    right: 0;
    transfrom: translate(-50%, 25%);
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -40px;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const NavMenuLinks = styled(Link)`
  ${NavLink}
`;

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 14px;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const Navbar = ({ toggle }) => {
  const [navBg, setNavBg] = useState(false);

  const changeBg = () => {
    if (window.scrollY >= 60) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }
  };

  window.addEventListener("scroll", changeBg);

  return (
    <Nav className={navBg && "scrolled"}>
      <Logo to="/">
        <img src={logo} alt="The GoodHouse PHC" />
        <span className="hide-text">The GoodHouse PHC</span>
      </Logo>
      <MenuBars onClick={toggle} />
      <NavMenu>
        {menuData.map((item, index) => (
          <NavMenuLinks to={item.link} key={index}>
            {item.title}
          </NavMenuLinks>
        ))}
        <NavBtn>
          <Button to="/listings" primary="true">
            Book Now
          </Button>
        </NavBtn>
      </NavMenu>
    </Nav>
  );
};

export default Navbar;
