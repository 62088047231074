import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import roomImg from "../images/8.jpg";

const InfoDiv = styled.section`
  width: 100%;
  height: 100%;
  padding: 1rem 0rem;
  margin: 0.5rem auto;

  @media screen and (max-width: 480px) {
    width: 95%;
    padding: 0rem;
    margin: 0 auto;
  }
`;

const Container = styled.div`
  padding: 2rem calc((100vw - 1300px) / 2);
  margin: 0.5rem auto 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  grid-template-rows: 600px;

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    padding: 0;
    margin: 0 auto;
    gap: 0;
    grid-template-rows: 400px;
  }
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.4;
  padding: 1rem 2rem;
  //   order: $/{({reverse}) => (reverse ? '2' : '1')};

  h1 {
    color: var(--primary-color);
    margin-bottom: 1rem;
    font-size: clamp(1.5rem, 6vw, 2rem);
    text-align: center;
  }

  p {
    margin-bottom: 2rem;
    text-align: center;
  }

  h4 {
    color: #94410a;
    margin-top: 2rem;
  }

  ul {
    margin: 0.5rem auto;
    line-height: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  li {
    text-decoration: none;
    color: var(--dark-bg-txt);
    list-style: square;
    margin: 0 1.5rem;
  }

  @media screen and (max-width: 480px) {
    padding: 0;

    li {
      margin: 3px;
    }
  }
`;

const ColumnRight = styled.div`
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 90%;
    padding: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const InfoSection = () => {
  return (
    <InfoDiv>
      <Container>
        <ColumnLeft>
          <h1>Discover What We Offer</h1>
          <p>
            We provide luxury short-let apartments for business or pleasure
            stays. Our apartments are designed to pamper guests and provide
            convenience for their every need.
          </p>
          <p>
            We go the extra mile by offering amenities like swimming pools,
            mini-gyms, free wi-fi, cable tv and free parking. In addition, our
            personnel are polite and always helpful. Our packages are guaranteed
            to leave you with pleasant memories.
          </p>
          <Button to="/rooms" primary="false">
            Book Now
          </Button>
        </ColumnLeft>
        <ColumnRight>
          <img src={roomImg} alt="" />
        </ColumnRight>
      </Container>
    </InfoDiv>
  );
};

export default InfoSection;
