import React from "react";
import PageHero from "../components/PageHero";
import TestPage from "../components/TestPage";

const Contact = () => {
  return (
    <div>
      <PageHero name="Contact Us" />
      <TestPage />
    </div>
  );
};

export default Contact;
