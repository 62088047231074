import moment from "moment";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import { items } from "../data/RoomData";
import defaultBcg from "../images/3.jpg";

const Booknow = ({ onAdd }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(0); // Define amount state
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [days, setDays] = useState(0);
  const params = useParams();
  const [message, setMessage] = useState("");
  // const startDate = new Date()
  // const endDate = new Date()
  useEffect(() => {
    if (fromDate && toDate) {
      const inDate = moment(fromDate, "YYYY-MM-DD");
      const outDate = moment(toDate, "YYYY-MM-DD");
      const bookedDays = outDate.diff(inDate, "days");

      const selectedRoom = items.find(
        (room) => room.fields.slug === params.slug
      );
      const roomPrice = selectedRoom ? selectedRoom.fields.price : 0;

      const totalAmount = bookedDays * roomPrice;
      setAmount(totalAmount);
    }
  }, [fromDate, toDate, params.slug]);

  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: amount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_live_c2c96d338601bbb246cd2328c6447885070a6c3c",
  };

  const initializePayment = usePaystackPayment(config);

  // you can call this function anything
  const onSuccess = (reference) => {
    console.log(reference);
    setMessage("Booking successful with reference " + reference);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
    setMessage("Closed");
  };

  let handleName = (e) => {
    // let name = e.target.value;
    setName(e.target.value);
    // console.log(name);
  };
  let handlePhone = (e) => {
    // let phone = e.target.value;
    setPhone(e.target.value);
    // console.log(phone);
  };
  let handleEmail = (e) => {
    setEmail(e.target.value);
  };
  let handleCheckIn = (e) => {
    // let checkInDate = e.target.value;
    setFromDate(e.target.value);
    // console.log(checkInDate);
  };

  let handleCheckOut = (e) => {
    // let checkOutDate = e.target.value;
    setToDate(e.target.value);
    // console.log(checkOutDate);
  };

  const calculateDays = (e) => {
    // let oned = 24 * 60 * 60 * 1000
    // setDays(Math.ceil((Date(toDate) - Date(fromDate)) / oned))
    let inDate = moment(fromDate, "YYYY-MM-DD");
    let outDate = moment(e.target.value, "YYYY-MM-DD");
    const Diff = moment.duration(outDate.diff(inDate)).asDays();
    // console.log(Diff);
    setDays(Diff);
  };

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   axios
  //     .post("http://localhost:8080/reservations", {
  //       // .post("https://api.thegoodhouseng.com/reservations", {
  //       name: name,
  //       phone: phone,
  //       amount: amount,
  //       room_id: "1",
  //       checkin_date: fromDate,
  //       checkout_date: toDate,
  //     })
  //     .then((res) => {
  //       initializePayment(onSuccess, onClose);
  //       // console.log("ok", res);
  //       // setMessage("Booking successful");
  //     })
  //     .catch((err) => {
  //       console.log("error", err);
  //     });
  // };

  const onSubmit = (e) => {
    e.preventDefault();

    // Calculate the number of days between check-in and check-out dates
    const inDate = moment(fromDate, "YYYY-MM-DD");
    const outDate = moment(toDate, "YYYY-MM-DD");
    const days = outDate.diff(inDate, "days");

    // Retrieve the selected room
    const selectedRoom = items.find((room) => room.fields.slug === params.slug);

    // Calculate the total amount based on the number of days and room price
    const totalAmount = days * selectedRoom.fields.price;

    // Make the POST request to the server
    axios
      // .post("https://api.thegoodhouseng.com/reservations", {
      .post("http://localhost:8080/reservations", {
        name: name,
        phone: phone,
        email: email,
        amount: totalAmount,
        room_id: selectedRoom.sys.id,
        checkin_date: fromDate,
        checkout_date: toDate,
      })
      .then((res) => {
        // Initialize payment after successful reservation
        initializePayment(onSuccess, onClose);
        // setMessage("Booking successful with reference " + res.data.reference);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  // const PaystackHookExample = () => {
  //   const initializePayment = usePaystackPayment(config);
  //   return (
  //     <div>
  //       <button
  //         onClick={() => {
  //           initializePayment(onSuccess, onClose);
  //         }}
  //       >
  //         Confirm Booking
  //       </button>
  //     </div>
  //   );
  // };

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     let res = await fetch("http://localhost:8080/reservations", {
  //       method: "POST",
  //       body: JSON.stringify({
  //         room_id: 1,
  //         checkin_date: fromDate,
  //         checkout_date: toDate,
  //       }),
  //     });
  //     //   let resJson = await res.json();
  //     if (res.status === 200) {
  //       setFromDate("");
  //       setToDate("");
  //       setMessage("User created successfully");
  //     } else {
  //       setMessage("Some error occured");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   if (!fromDate) {
  //     alert("Please select a valid date...");
  //     return;
  //   }

  //   // onAdd({ fromDate, toDate });

  //   setFromDate("");
  //   setToDate("");
  // };

  return (
    <div>
      {items
        .filter((room) => room.fields.slug === params.slug)
        .map((room) => (
          <>
            <div className="container py-5" key={room.sys.id}>
              <div className="row">
                <div className="col-md-10 mx-auto col-12 card shadow-lg border-0 p-4">
                  <div>
                    <h1 className="display-4">Booking</h1>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-12 my-auto">
                      <img
                        src={
                          room.fields.images[0].fields.file.url || defaultBcg
                        }
                        className="img-fluid"
                        alt="selected room"
                      />
                    </div>
                    <div className="col-md-6 col-12 my-auto">
                      <h1>Rooms Details</h1>
                      <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th className="bg-light">Room Type</th>
                            <td>{room.fields.name}</td>
                          </tr>
                          <tr>
                            <th className="bg-light">Capacity</th>
                            <td>{room.fields.capacity}</td>
                          </tr>
                          <tr>
                            <th className="bg-light">Size</th>
                            <td>{room.fields.size} sqft.</td>
                          </tr>
                          <tr>
                            <th className="bg-light">Breakfast</th>
                            <td>
                              {room.fields.breakfast === true
                                ? `Included`
                                : `Not Included`}
                            </td>
                          </tr>
                          <tr>
                            <th className="bg-light">Pets</th>
                            <td>
                              {room.fields.pets === true
                                ? `Allowed`
                                : `Not Allowed`}
                            </td>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  <form method="POST" onSubmit={onSubmit}>
                    <div className="row my-3">
                      {/* <iframe
                        src="https://calendar.google.com/calendar/embed?src=thegoodhousephc%40gmail.com&ctz=Africa%2FLagos"
                        style={{ border: 0 }}
                        width="800"
                        height="600"
                        frameborder="0"
                        title="g_calendar"
                        scrolling="no"
                      ></iframe> */}
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label
                            htmlFor="Fromname"
                            className="font-weight-bolder mr-3"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            placeholder="Fullname"
                            value={name}
                            onChange={handleName}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label
                            htmlFor="Fromphone"
                            className="font-weight-bolder mr-3"
                          >
                            Phone Number
                          </label>
                          <input
                            type="text"
                            placeholder="Phone Number"
                            value={phone}
                            onChange={handlePhone}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label
                            htmlFor="Fromemail"
                            className="font-weight-bolder mr-3"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            placeholder="mail@mail.com"
                            value={email}
                            onChange={handleEmail}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label
                            htmlFor="Fromdate"
                            className="font-weight-bolder mr-3"
                          >
                            From Date{" "}
                          </label>
                          <input
                            type="date"
                            onChange={handleCheckIn}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label
                            htmlFor="Todate"
                            className="font-weight-bolder mr-3"
                          >
                            To Date{" "}
                          </label>
                          <input
                            type="date"
                            onChange={(e) => {
                              handleCheckOut(e);
                              calculateDays(e);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <h6 className="font-weight-bolder">
                          Number of days : {days}
                        </h6>
                        <mark>
                          Please make sure Check in time is from 12pm to check
                          out 2pm
                        </mark>
                      </div>
                      <div className="col-md-6 col-12">
                        {room.fields.weekend_price ? (
                          <>
                            <h6 className="font-weight-bold">
                              Price per day (Monday to Thursday) :
                              <span className="text-primary">
                                ₦ {room.fields.price.toLocaleString()}
                              </span>
                            </h6>
                            <h6 className="font-weight-bold">
                              Price per day (Friday to Sunday) :
                              <span className="text-primary">
                                ₦ {room.fields.weekend_price.toLocaleString()}
                              </span>
                            </h6>
                            <h6 className="font-weight-bold">
                              Cleaning Fee :
                              <span className="text-primary">
                                ₦ {room.fields.cleaning.toLocaleString()}
                              </span>
                            </h6>
                            <h6 className="font-weight-bold">
                              Refundable Caution Fee :
                              <span className="text-primary">
                                ₦ {room.fields.caution.toLocaleString()}
                              </span>
                            </h6>
                          </>
                        ) : (
                          <h6 className="font-weight-bold">
                            Price per day :
                            <span className="text-primary">
                              ₦ {room.fields.price.toLocaleString()}
                            </span>
                          </h6>
                        )}

                        <h6 className="font-weight-bold">
                          Total Price to be paid :
                          <span className="text-primary">
                            ₦ {(room.fields.price * days).toLocaleString()}
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div className="row my-4">
                      {/* <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label
                            htmlFor="payment"
                            className="font-weight-bolder"
                          >
                            Payment Options
                          </label>
                          <select className="form-control">
                            <option disabled>Select payment option</option>
                            <option value="card_payment">Pay with Card</option>
                            <option value="checkin">Pay during Checkin</option>
                          </select>
                        </div>
                      </div> */}
                      <input
                        type="hidden"
                        defaultValue={room.sys.id}
                        className="form-control"
                      />
                      <div className="col-md-6 col-12 my-auto">
                        <div className="col-md-6 col-12 float-right">
                          <button
                            type="submit"
                            className="btn btn-block btn-outline-primary"
                            //   data-toggle="modal"
                            //   data-target="#thanks"
                          >
                            Confirm Booking
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="message">
                    {message ? <p>{message}</p> : null}
                  </div>
                </div>
              </div>
              <div className="modal fade" id="thanks">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body p-4">
                      <h3>Thank you </h3>
                      <p className="lead">
                        Your room is booked successfully....
                      </p>
                    </div>
                    <div className="modal-footer">
                      <Link to="/" className="btn btn-dark">
                        Goto Home
                      </Link>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            ;
          </>
        ))}
    </div>
  );
};

export default Booknow;
