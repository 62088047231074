import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import SideBar from "./components/SideBar";
import GlobalStyle from "./globalStyles";
import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./pages/Home";
import Room from "./pages/Rooms";
import Booknow from "./pages/Booknow";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import EventsPage from "./pages/Events";
// import Login from "./pages/Login";
import SingleRoom from "./pages/SingleRoom";
import ScrollToTop from "./ScrollToTop";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <>
        <ScrollToTop />
        <GlobalStyle />
        <Navbar toggle={toggle} />
        <SideBar isOpen={isOpen} toggle={toggle} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/rooms/" element={<Room />} />
          <Route path="/listings/" element={<Room />} />
          <Route path="/events/" element={<EventsPage />} />
          <Route path="/services/" element={<Services />} />
          <Route path="/contact/" element={<Contact />} />
          {/* <Route path="/login/" element={<Login />} /> */}
          <Route path="/rooms/:slug" element={<SingleRoom />} />
          <Route path="/booknow/:slug" element={<Booknow />} />
        </Routes>
        <Footer />
      </>
    </>
  );
}

export default App;
