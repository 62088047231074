import React from "react";
import { Link } from "react-router-dom";
import { items } from "../data/RoomData";
// import defaultImg from "../images/1.jpeg";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const SingRoom = styled.div`
  .single-room {
    padding: 5rem 0 0 0;
  }
  .single-room-images {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
  }
  .single-room-images img {
    width: 100%;
    display: block;
  }
  .single-room-info {
    width: 80vw;
    display: grid;
    grid-template-columns: 1fr;
    margin: 2rem auto;
  }
  .desc,
  .info {
    margin: 1rem 0;
  }
  .desc h3 {
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  .desc p {
    line-height: 1.5;
  }
  .info h3,
  .info h6 {
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }

  .info h6 {
    font-weight: 300;
  }
  .room-extras {
    width: 80vw;
    margin: 0 auto 3rem auto;
  }
  .room-extras h6 {
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  .extras {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
  }
  @media screen and (min-width: 992px) {
    .single-room-images,
    .single-room-info,
    .room-extras {
      width: 95vw;
      max-width: 1170px;
    }
    .single-room-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    }
    .info {
      padding-left: 3rem;
    }
  }
`;

const SingleRoom = () => {
  const params = useParams();
  //console.log(params)
  return (
    <SingRoom>
      {items
        .filter((room) => room.fields.slug === params.slug)
        .map((room) => (
          <>
            <section className="single-room container">
              <div className="row">
                {room.fields.images.slice(1, 4).map((item, index) => {
                  return (
                    <div className="col-md-4 col-12 mx-auto" key={index}>
                      <div className="card border-0 shadow-lg">
                        <img
                          key={index}
                          src={item.fields.file.url}
                          alt={room.fields.name}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="single-room-info">
                <article className="desc">
                  <h3>Details</h3>
                  <p>{room.fields.description}</p>
                </article>
                <article className="info">
                  <h3>Info</h3>
                  <h6>price : #{room.fields.price.toLocaleString()}</h6>
                  <h6>size : {room.fields.size} SQFT</h6>
                  <h6>
                    max capacity :{" "}
                    {room.fields.capacity > 1
                      ? `${room.fields.capacity} people`
                      : `${room.fields.capacity} person`}
                  </h6>
                  <h6>
                    {room.fields.pets ? "pets allowed" : "no pets allowed"}
                  </h6>
                  <h6>{room.fields.breakfast && "free breakfast included"}</h6>
                </article>
              </div>
            </section>
            <section className="room-extras container">
              <h3>Extras</h3>
              <ul className="extras">
                {room.fields.extras.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
              <div className="p-4 clearfix">
                <div className="row">
                  <div className="col-md-3 col-12 ml-auto">
                    <Link
                      to={`/booknow/${params.slug}`}
                      className="btn btn-outline-primary btn-block btn-lg float-right "
                    >
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </>
        ))}
    </SingRoom>
  );
};

export default SingleRoom;
