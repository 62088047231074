import GHPHC3 from "../images/Good House/GHPHC3.jpg";
import ImageOne from "../images/R & B/5.jpg";
import ImageThree from "../images/Good House/5.jpeg";
import ImageFour from "../images/Something New Restaurant/11.jpg";
import ImageFive from "../images/Good House/7.JPG";
import ImageSix from "../images/R & B/2.jpg";
import ImageSeven from "../images/Good House/6.jpeg";
import ImageEight from "../images/Something New Restaurant/2.jpg";

import EventOne from "../images/events/1.jpg";
import EventTwo from "../images/events/2.jpg";
// import EventThree from "../images/events/3.jpg";
import EventFour from "../images/events/4.jpg";
import EventFive from "../images/events/5.jpg";
// import EventSix from "../images/events/6.jpg";
import EventSeven from "../images/events/7.jpg";

export const SliderData = [
  {
    title: "Welcome to The GoodHouse PHC",
    price: "#25,000.00",
    path: "/",
    label: "Book Now",
    image: GHPHC3,
    alt: "The Good House PHC",
  },
  {
    title: "",
    price: "#35,000.00",
    path: "/",
    label: "Book Now",
    image: ImageFive,
    alt: "The Good House PHC",
  },
  {
    title: "Rhythm and Blues",
    price: "#15,000.00",
    path: "/",
    label: "Book Now",
    image: ImageOne,
    alt: "The Good House PHC",
  },
  {
    title: "",
    price: "#35,000.00",
    path: "/",
    label: "Book Now",
    image: ImageSix,
    alt: "The Good House PHC",
  },
  {
    title: "Events and Celebrations",
    price: "#55,000.00",
    path: "/",
    label: "Book Now",
    image: ImageThree,
    alt: "The Good House PHC",
  },
  {
    title: "",
    price: "#35,000.00",
    path: "/",
    label: "Book Now",
    image: ImageSeven,
    alt: "The Good House PHC",
  },
  {
    title: "Restaurant and Bar",
    price: "#35,000.00",
    path: "/",
    label: "Book Now",
    image: ImageFour,
    alt: "The Good House PHC",
  },
  {
    title: "",
    price: "#35,000.00",
    path: "/",
    label: "Book Now",
    image: ImageEight,
    alt: "The Good House PHC",
  },
];

export const SliderDataTwo = [
  {
    title: "",
    price: "#25,000.00",
    path: "/",
    label: "Book Now",
    image: EventOne,
    alt: "The Good House PHC",
  },
  {
    title: "",
    price: "#15,000.00",
    path: "/",
    label: "Book Now",
    image: EventSeven,
    alt: "The Good House PHC",
  },
  {
    title: "",
    price: "#35,000.00",
    path: "/",
    label: "Book Now",
    image: EventTwo,
    alt: "The Good House PHC",
  },
  {
    title: "",
    price: "#35,000.00",
    path: "/",
    label: "Book Now",
    image: EventFour,
    alt: "The Good House PHC",
  },
  {
    title: "",
    price: "#35,000.00",
    path: "/",
    label: "Book Now",
    image: EventFive,
    alt: "The Good House PHC",
  },
];
