import React from "react";
import styled from "styled-components";
import goodHouse from "../images/Good House/16.jpg";
import roomImg from "../images/8.jpg";
import roomBlue from "../images/R & B/21.jpg";

const Container = styled.div`
  padding: 5rem;
  width: 100%;
  line-height: 2rem;
  //   background: rgba(83, 83, 83, 0.1);

  @media screen and (max-width: 760px) {
    padding: 2rem;
    font-size: 0.9rem;
  }
`;

const List = () => {
  return (
    <div>
      <Container>
        <div className="row">
          <div className="col-md-4 col-12 mx-auto">
            <div className="card border-0 shadow-lg">
              <img src={goodHouse} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-4 col-12 mx-auto">
            <div className="card border-0 shadow-lg">
              <img src={roomImg} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-4 col-12 mx-auto">
            <div className="card border-0 shadow-lg">
              <img src={roomBlue} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default List;
